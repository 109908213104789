<template>
  <CModal id="CreateHCTLogisticsOrder" class="ActionModel" :show.sync="Toggle" :centered="true" size="lg" :closeOnBackdrop="!Submit"
          @update:show="HandleHideModal">
    <template>
      <CAlert v-if="OrderList.length > 0" color="info">
        <CIcon name="cil-bell" /> 系統將會自動篩選出條件為<b>{{ $t('Logistics.LogisticsMethods.HCT') }}</b>配送方式、<b>待出貨</b>以及<b>已付款</b>及<b>代收款</b>的訂單。<br><b>請注意！</b>已經建立過物流單的訂單將不會重複拋單，若您需要重新批量建立物流單，請將訂單的物流狀態改為「<b>未出貨</b>」。
      </CAlert>
      <CAlert v-if="OrderList.length <= 0" color="warning">
        <CIcon name="cil-bell" /> 當前篩選的訂單條件配送方式並未找到<b>待出貨</b>的<b>{{ $t('Logistics.LogisticsMethods.HCT') }}</b>訂單，或訂單狀態並非<b>已付款</b>或<b>代收款</b>。
      </CAlert>
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsSystem') }}
        </CCol>
        <CCol sm="9" class="d-flex align-items-center">
          {{ $t('Logistics.LogisticsSystem.HCT-LOGISTICS') }}
        </CCol>
      </CRow>
      <CRow v-if="AllCondition" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.DataTime') }}
        </CCol>
        <CCol sm="9" class="d-flex align-items-center">
          {{ $dayjs($parent.SearchFilter.StartTime).format('YYYY-MM-DD HH:mm:ss') }} ~  {{ $dayjs($parent.SearchFilter.EndTime).format('YYYY-MM-DD HH:mm:ss') }}
        </CCol>
      </CRow>
      <CRow v-if="AllCondition && $parent.SearchFilter.StartReceiverDate && $parent.SearchFilter.EndReceiverDate" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.ReceiverDate') }}
        </CCol>
        <CCol sm="9" class="d-flex align-items-center">
          {{ $dayjs($parent.SearchFilter.StartReceiverDate).format('YYYY-MM-DD') }} ~ {{ $dayjs($parent.SearchFilter.EndReceiverDate).format('YYYY-MM-DD') }}
        </CCol>
      </CRow>
      <CSelect horizontal :label="$t('Logistics.Name')" :options="LogisticsOption" v-model="LogisticsData.ID" :value.sync="LogisticsData.ID" :placeholder="$t('Global.PleaseSelect')" @change="GerOrderList()" />
      <CInput :label="$t('Order.LogisticsOrderOption.OrderCount')" :value="OrderList.length" horizontal :disabled="true" />
      <CInput id="Weight" :label="$t('Product/Detail.Weight')" type="number" v-model="LogisticsData.Weight" :placeholder="$t('Message.Order/WeightEmpty')" horizontal>
        <template #append-content>KG</template>
      </CInput>
      <CRow form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Logistics.ExpectedShippingDate') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="LogisticsData.ShippingDate" mode="date">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput :value="inputValue" v-on="inputEvents" :placeholder="$t('Logistics.ShippingDateInfo')">
                <template #prepend-content>
                  <CIcon name="cil-calendar"/>
                </template>
                <template #append>
                  <CButton type="button" color="danger" @click="LogisticsData.ShippingDate = ''">
                    <CIcon size="sm" name="cil-x"/>
                  </CButton>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Logistics.AssignDate') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="LogisticsData.AssignDate" mode="date">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput :value="inputValue" v-on="inputEvents" :placeholder="$t('Logistics.AssignDateInfo')">
                <template #prepend-content>
                  <CIcon name="cil-calendar"/>
                </template>
                <template #append>
                  <CButton type="button" color="danger" @click="LogisticsData.AssignDate = ''">
                    <CIcon size="sm" name="cil-x"/>
                  </CButton>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CSelect horizontal :label="$t('Logistics.Temperature')" :options="TemperatureOptions" v-model="LogisticsData.Temperature" :value.sync="LogisticsData.Temperature" :placeholder="$t('Global.PleaseSelect')"/>
    </template>
    <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Order.BulkCreateLogisticsOrder') }}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton id="CreateLogisticsOrderSubmit" @click="BulkCreateLogisticsOrder()" color="success" :disabled="OrderList.length <= 0">
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "CreateLogisticsOrder",
  props: ['Toggle', 'OrderNum', 'AllCondition'],
  data() {
    return {
      Submit: false,
      Loading: true,
      LogisticsData: {
        ID: '',
        Temperature: 'Common'
      },
      OrderList: [],
      LogisticsOption: [],
      LogisticsListObject: {}
    }
  },
  computed: {
    TemperatureOptions() {
      return Object.keys(this.$t('Logistics.TemperatureOptions')).map(status => {
        return {
          value: status,
          label: this.$t('Logistics.TemperatureOptions')[status]
        }
      })
    }
  },
  mounted() {
   return Promise.all([
     this.GetLogistics()
   ]).then(() => {
     this.Loading = false
   }).catch((error) => {
     console.log(error)
     this.Loading = false
     this.$notify({
       group: 'notify',
       title: this.$t('Message.Error'),
       text: this.$t('Message.Global/ErrorMessage') + error,
       type: 'error'
     })
   })
  },
  watch: {
  },
  methods: {
    HandleHideModal() {
      this.$emit('update:Toggle', false)
      this.$emit('update:OrderNum', false)
      this.$emit('update:AllCondition', false)
    },
    GerOrderList() {
      const params = {
        Logistics: 'HCT',
        LogisticsID: this.LogisticsData.ID,
        LogisticsSystem: 'HCT-LOGISTICS',
        LogisticsStatus: 'P',
        PaymentStatus: ['Y', 'A'].join(',')
      }
      if (this.AllCondition) {
        params.StartTime = this.$dayjs(this.$parent.SearchFilter.StartTime).unix()
        params.EndTime = this.$dayjs(this.$parent.SearchFilter.EndTime).unix()
        if (this.$parent.SearchFilter.StartReceiverDate && this.$parent.SearchFilter.EndReceiverDate) {
          params.StartReceiverDate = this.$dayjs(this.$parent.SearchFilter.StartReceiverDate).format('YYYY-MM-DD')
          params.EndReceiverDate = this.$dayjs(this.$parent.SearchFilter.EndReceiverDate).format('YYYY-MM-DD')
        }
      } else {
        params.OrderNum = this.OrderNum.join(',')
        params.SearchAllTimeRange = true
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/order/systemList',
        method: 'get',
        params
      }).then(async ({data}) => {
        this.Loading = false
        this.OrderList = data.list
      }).catch((error) => {
        this.Loading = false
        throw error
      })
    },
    GetLogistics() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/list',
        method: 'get'
      }).then(({data}) => {
        this.LogisticsOption = Object.keys(data).filter(id => data[id].System === 'HCT-LOGISTICS').map((id) => {
          return {
            value: id,
            label: `${data[id].Name} (${this.$t('Order.LogisticsType')}: ${this.$t('Logistics.LogisticsMethods.' + data[id].Method)}，${this.$t('Logistics.Fee')}: ${data[id].Fee}，${this.$t('Logistics.ServiceLocation')}: ${this.$t('Logistics.ServiceLocationOptions.' + data[id].ServiceLocation)})`
          }
        })
        this.LogisticsListObject = data
      }).catch((err) => {
        throw err
      })
    },
    BulkCreateLogisticsOrder() {
      this.Submit = true
      this.$Progress.start()
      if (!this.LogisticsListObject[this.LogisticsData.ID].ShipperInfo) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Order/CreateLogisticsOrderSuccess'),
          type: 'success'
        })
        return false
      }
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/order/bulkCreateLogisticsOrder',
        method: 'post',
        data: {
          ...this.LogisticsData,
          OrderData: this.OrderList.map(item => item.OrderNum),
          Logistics: this.LogisticsListObject[this.LogisticsData.ID]
        }
      }).then(({data}) => {
        this.Submit = false
        this.$Progress.finish()
        this.$emit('Success', data.detail)
        const SuccessData = data.detail.filter(item => item.Success === 'Y')
        const FailData = data.detail.filter(item => item.Success !== 'Y')
        this.$notify({
          group: 'notify',
          title: this.$t('Message.ExecuteFinish'),
          text: this.$t('Message.Order/BulkCreateLogisticsOrderFinish', { success: SuccessData.length, fail: FailData.length, total: data.detail.length}),
          type: 'info',
          duration: 10000
        })
        this.HandleHideModal()
      }).catch((error) => {
        this.Submit = false
        this.$Progress.fail()
        this.$emit('Error', error)
        this.HandleHideModal()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Order/CreateLogisticsOrderFail') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    }
  }
}
</script>

<style lang="scss">
#CreateLogisticsOrder {
  label {
    font-weight: bold;
  }
}
</style>
